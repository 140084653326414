<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="isLoadingXeroList" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-data-table
            v-if="!isLoadingXeroList"
            :items="filteredData"
            :headers="dataHeader">
            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    Invoices
                                </v-toolbar-title>
                                <v-spacer>
                                </v-spacer>
                                <v-btn
                                    small
                                    class="ma-3"
                                    :to="{name:'PageUploadXeroInvoice'}"
                                    color="primary">
                                    <span
                                        class="mx-2 my-3">
                                        Upload Original Invoices
                                    </span>
                                </v-btn>
                            </v-row>
                            <!-- <v-row>
                                <v-col
                                    class="px-0 pb-0"
                                    cols="6">
                                    <v-select
                                        style="border-radius: 6px 0px 0px 6px;"
                                        :items="filterHeaders"
                                        dense 
                                        outlined
                                        v-model="selectedFilterHeaders"
                                        item-text="text"
                                        item-value="value">
                                    </v-select>
                                </v-col>
                                <v-col
                                    class="px-0"
                                    cols="6">
                                    <v-text-field
                                        style="border-radius: 0px 0px 0px 0px;"
                                        v-model="search"
                                        dense
                                        outlined 
                                        label="Search">
                                    </v-text-field>
                                </v-col>
                            </v-row> -->
                            <!-- <v-row>
                                <v-col
                                    class="pt-0">
                                    <div
                                        class="d-flex justify-end pt-0 px-0"
                                        style="margin-top:-20px;margin-bottom:20px">
                                        <v-btn
                                            color="primary"
                                            small
                                            style="border-radius:4px 0px 0px 4px"
                                            @click="resetValue"
                                            outlined >
                                        Reset Filter
                                        </v-btn>
                                        <v-btn
                                            style="border-radius: 0px 4px 4px 0px;"
                                            class="py-0"
                                            color="primary"
                                            elevation="0"
                                            small
                                            @click="FilterValue">
                                            Filter
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row> -->
                            <AFilterWithoutRefresh
                                @reset="(data)=>{
                                    filteredData = data;
                                }"
                                @filter="(data)=>{
                                    filteredData = data
                                }"
                                @refresh="fetch"
                                :data="data"
                                :filterHeaders="filterColumns"/>
                        </v-col>
                    </v-row>
                </v-toolbar>

            </template>
            <!-- <template
                v-slot:item.email_send_at="{item}">
                <ADateFormat
                    :date="item.email_send_at">
                </ADateFormat>
            </template> -->
            <template
                v-slot:item.index="{item}">
                {{ data.indexOf(item) +1 }}
            </template>
            <!-- <template
                v-slot:item.created_at="{item}">
                {{ convertTimeZone(item.created_at) }}
            </template> -->
            <template
                v-slot:item.invoice_link="{item}">
                <a
                    v-if="item.invoice_link!=null"
                    :href="item.invoice_link"
                    target="_blank">
                    <!-- {{ item.invoice_link }} -->
                    View invoice
                </a>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.is_send_email="{item}">
                    <v-chip
                        outlined
                        color="success"
                        v-if="item.is_send_email==1">
                        Yes
                    </v-chip>
                    <v-chip
                        outlined
                        color="orange"
                        v-if="item.is_send_email==0">
                        No
                    </v-chip>
            </template>
            <template
                v-slot:item.client_invoice_link="{item}">
                <a
                    v-if="item.client_invoice_link!=null"
                    :href="item.client_invoice_link"
                    target="_blank">
                    <!-- {{ item.client_invoice_link.split("/").pop()}} 
                    -->
                    View invoice
                </a>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.action="{item}">
                <v-row>
                    <v-col>
                        <v-menu
                            offset-y>
                            <template
                                v-slot:activator="{on, attrs}">
                                <v-btn
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    color="primary">
                                    Actions
                                    <v-icon>
                                        mdi-chevron-up
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    style="font-size:16px"
                                    v-for="(data,index) in actions"
                                    :key="index"
                                    @click="executeAction(data.action,item)">
                                        {{ data.name }}
                                </v-list-item>
                            </v-list>

                        </v-menu>
                    </v-col>
                </v-row>
            </template>
            <template
                v-slot:item.company_name="{item}">
                <router-link
                    v-if="item.company_id!=null"
                    :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                    {{ item.company_name }}
                </router-link>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.invoice_no="{item}">
                <ACopyAbleText
                :text="item.invoice_no"/>

            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import AFilterWithoutRefresh from '../../components/common/filter/AFilterWithoutRefresh.vue';
export default {
    components:{
    ASuccessWithoutReload,
    AFilterWithoutRefresh,
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        search:"",
        data:null,
        filteredData:null,
        filterColumns :[
            {
                text:'Company',
                value:'company_name'
            },
            {
                text:'Invoice number',
                value:'invoice_no',
            },
            {
                text:'Client invoice sent email status?',
                value:'is_send_email',
                type:'boolean',
                options:[
                    {
                        text:'Yes',
                        value:1
                    },
                    {
                        text:"No",
                        value:0
                    },
                ]
            },
            {
                text:'Send email date',
                value:'email_send_at',
                type:'date'
            },
            {
                text:'Created date',
                value:'created_at_invoice',
                type:'date',
                
            },
        ],
        dataHeader:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'Company',
                value:'company_name'
            },
            {
                text:'Invoice number',
                value:'invoice_no',
            },
            {
                text:'Original invoice',
                value:'invoice_link'
            },
            {
                text:'Modified invoice',
                value:'client_invoice_link'
            },
            {
                text:'Client invoice sent email status?',
                value:'is_send_email',
                type:'boolean',
                options:[
                    {
                        text:'Yes',
                        value:1
                    },
                    {
                        text:"No",
                        value:0
                    },
                ]
            },
           
            {
                text:'Send email date',
                value:'email_send_at'
            },
            {
                text:'Created date',
                value:'created_at_invoice'
            },
            {
                text:'Action',
                value:'action',
            }
        ],
        selectedFilterHeaders:'company_name',
        filterHeaders:[
            {
                text:'Company',
                value:'company_name'
            },
            {
                text:'Invoice number',
                value:'invoice_no',
            },
            {
                text:'Client invoice sent email status ?',
                value:'is_send_email'
            },
        ],
        actions:[
            {
                name:'Edit Original Invoice',
                action:'PageEditXeroInvoice',
            },
            {
                name:'Send email',
                action:'PageSendXeroInvoice',
            }
        ],
        isPending:false,
        isLoadingXeroList:true,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            try{
                if(resp.class==="getAllXeroInvoice") {
                    let tempData = resp.data;
                    this.data = tempData
                    this.filteredData = this.$_.clone(this.data);
    
                    this.isLoadingXeroList = false;
                }
              
                this.api.isLoading = false;
                this.api.isError = false;

            }catch(exception) {
                alert(exception);
            }
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchXeroInvoice());
            
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function
        fetchXeroInvoice() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/xero/invoices";
            return tempApi;
        },
       
        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },
        convertTimeZone(time){
            return this.$moment(time).format("LLL");
        },
        resetValue() {
            let tempInvoice = this.$_.clone(this.data);
            this.filteredData = tempInvoice;
            this.search="";
            this.selectedFilterHeaders = "company_name";
        },
        FilterValue() {
            let TempFilterHeader = this.$_.clone(this.selectedFilterHeaders);
            let tempFilterValue = this.$_.clone(this.search);
            let tempInvoice = this.$_.clone(this.data);
            if(TempFilterHeader==='is_send_email') {
                tempFilterValue = (tempFilterValue.toLowerCase()==='yes')?"1":"0";
            }

            tempInvoice = tempInvoice.filter(item=>{
                if(item[TempFilterHeader]!==null && item[TempFilterHeader]!=="null" ) {
                   return item[TempFilterHeader].toLowerCase().includes(tempFilterValue.toLowerCase())
                }
            })
            this.filteredData = tempInvoice;
        
        },
        executeAction(action,invoice) {
            if(action==='PageEditXeroInvoice') {
                this.$router.push({
                    name:action,
                    params:{
                        id:invoice.invoice_id,
                        company_id:invoice.company_id,
                        type:invoice.attachment_type
                    },
                });
            }
            if(action==='PageSendXeroInvoice') {
                this.$router.push({
                    name:action,
                    params:{
                        id:invoice.invoice_id,
                        company_id:invoice.company_id,
                    }
                })
            }
        },
        formatDate(isoString) {
            const date = new Date(isoString);
            function pad(number) {
                return number < 10 ? '0' + number : number;
            }
            return date.getFullYear() + '-' + 
                pad(date.getMonth() + 1) + '-' + 
                pad(date.getDate()) + ' ' + 
                pad(date.getHours()) + ':' + 
                pad(date.getMinutes()) + ':' + 
                pad(date.getSeconds());
        }
        
    }
}
</script>